import React from 'react'

import { Input } from '@cvg/react-components'
import { languageCodes } from '@cvg/content-review-localizations'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const StepSourceLanguage = () => {
  const { data, sourceLanguage, updateSourceLanguage } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  if (!data.confirmSourceLanguage) return null

  return (
    <div className={styles['source-language']}>
      <Input
        className={styles.language}
        label={getTranslation('Source Language')}
        name='language'
        onChange={({ target }) => updateSourceLanguage(target.value)}
        type='select'
        value={sourceLanguage}
      >
        {Object.values(languageCodes)
          .map(code => ({ code, language: getTranslation(code) }))
          .sort((a, b) => a.language.localeCompare(b.language))
          .map(({ code, language }) => (
            <option key={code} value={code}>
              {language}
            </option>
          ))}
      </Input>
    </div>
  )
}

export default StepSourceLanguage
