import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

import FlagContent from '../../FlagContent'

import styles from './styles.module.css'

const Select = ({ content }) => {
  const { currentStep, updateContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.select}>
      <h2>{getTranslation(content.label)}</h2>
      <ul>
        {content.contentItems.map(contentItem => (
          <li key={contentItem.id}>
            <Input
              className={styles.select}
              error={currentStep.errors[contentItem.id]}
              name={contentItem.id}
              onChange={event => updateContent(event.target)}
              readOnly={contentItem.readOnly}
              title={contentItem.readOnly ? getTranslation('This value may not be edited') : ''}
              type='select'
              value={contentItem.data}
            >
              {contentItem.allowedValues.map(option => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </Input>

            <FlagContent contentItem={contentItem} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select
