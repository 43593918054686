import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useLanguageContext } from '@cvg/react-hooks'

import Menu from '@components/Menu'

import styles from './styles.module.css'

const Header = ({ backPath = '/', text = 'Back to Queue' }) => {
  const { pathname } = useLocation()
  const { getTranslation } = useLanguageContext()

  return (
    <header className={styles.header} data-url={pathname}>
      <Link to={backPath}>{getTranslation(text)}</Link>
      <Menu />
    </header>
  )
}

export default Header
