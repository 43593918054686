import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useLanguageContext } from '@cvg/react-hooks'

import useReviewReducer from './useReviewReducer.js'

import { getReview, getContextReviewsCacheKey, postReview } from '@services/contentReview'
import { endReviewTimer, startReviewTimer } from '@utils/analytics'
import { setupSteps } from '@utils/config'
import { ERROR_STATUS_ALREADY_REVIEWED } from '@utils/constants'
import { getOptimisticUIReviews } from '@utils/swr'
import { validateStep } from '@utils/validations'

import { showError, showSuccess, showWarning } from '@cvg/react-components'

import { useFetch, useSubmit } from '@cvg/react-hooks'
import { useSearchContext } from '../../components/AppProviders/Search/index.jsx'
import useFlags from '@hooks/useFlags.js'

const useReviewData = () => {
  const { search } = useSearchContext()
  const navigate = useNavigate()
  const { id } = useParams()
  const { getTranslation, languageId } = useLanguageContext()
  const { state, dispatch } = useReviewReducer()

  const { flag, setFlag, overlappingFlag, setOverlappingFlag } = useFlags(state.steps, dispatch)

  const { data, error: fetchError, isLoading } = useFetch(() => getReview(id, languageId))

  const { submit, isSubmitting } = useSubmit(postReview, {
    onError: error => {
      if (error.status === ERROR_STATUS_ALREADY_REVIEWED) {
        navigate('/')
        showWarning(getTranslation('ALREADY_REVIEWED_MESSAGE'))
      } else {
        showError(getTranslation('ERROR_MESSAGE'))
      }
    },
    onSuccess: () => {
      navigate('/')
      showSuccess(getTranslation('SUCCESS_MESSAGE'))
      endReviewTimer(data)
    },
    pagination: {
      getCacheKey: (index, previousData) => getContextReviewsCacheKey(data?.context, index, previousData, languageId, search)
    },
    swrOptions: {
      optimisticData: previousData => getOptimisticUIReviews(data.id, previousData)
    }
  })

  useEffect(() => {
    if (data) {
      dispatch({ type: 'INITIALIZE_STEPS', steps: setupSteps(data), sourceLanguage: data.sourceLanguage })
      startReviewTimer()
    }
  }, [data])

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [state.currentStepNumber])

  const callbacks = {
    flagContent: (contentId, flags, step) => dispatch({ type: 'FLAG_CONTENT', contentId, flags, step }),
    goToNextStep: step => dispatch({ type: 'NEXT_STEP', step }),
    goToPreviousStep: stepNumber => dispatch({ type: 'PREVIOUS_STEP', stepNumber }),
    submitContent: () => submit({ id, language: data?.targetLanguage, state }),
    toggleHelpText: () => dispatch({ type: 'TOGGLE_HELP_TEXT' }),
    updateComment: event => dispatch({ type: 'UPDATE_COMMENT', event }),
    updateContent: target => dispatch({ type: 'UPDATE_CONTENT', target }),
    updateSourceLanguage: sourceLanguage => dispatch({ type: 'UPDATE_SOURCE_LANGUAGE', sourceLanguage }),
    validateContent: step => {
      const errors = validateStep(step)
      const hasErrors = !!Object.entries(errors).length

      dispatch({ type: 'VALIDATE_CONTENT', step, errors })

      if (hasErrors) showError(getTranslation('VALIDATION_MESSAGE'))
      return !hasErrors
    }
  }

  return {
    ...callbacks,
    context: data?.context,
    currentStep: state.steps[state.currentStepNumber - 1],
    data,
    fetchError,
    flag,
    setFlag,
    overlappingFlag,
    setOverlappingFlag,
    isLoading,
    isSubmitting,
    ...state
  }
}

export default useReviewData
