import React from 'react'
import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Notifications = () => {
  const { isCurrentUser, isSubmitting, onChange, updatedUser } = useCustomContext()
  const { getTranslation, supportedLanguages } = useLanguageContext()

  return (
    <fieldset>
      <legend>{getTranslation('Notifications')}</legend>
      <div className={styles.notifications}>
        <Input
          disabled={isSubmitting}
          label={getTranslation('Receive daily emails for pending reviews')}
          name='notificationsEnabled'
          onClick={e => onChange(e.target.name, !updatedUser.notificationsEnabled)}
          type='toggle'
          value={!!updatedUser.notificationsEnabled}
        />

        {/* The app preferences language selector in current user settings conflicts with this one */}
        {updatedUser.notificationsEnabled && !isCurrentUser && (
          <Input
            disabled={isSubmitting}
            label={getTranslation('Preferred language for emails')}
            name='locale'
            onChange={e => onChange(e.target.name, e.target.value)}
            type='select'
            value={updatedUser.locale}
          >
            {supportedLanguages.map(language => (
              <option key={language.id} value={language.id}>
                {language.name}
              </option>
            ))}
          </Input>
        )}
      </div>
    </fieldset>
  )
}

export default Notifications
