import { useSWRConfig } from 'swr'
import { useCustomContext, useLanguageContext, useSubmit } from '@cvg/react-hooks'
import { showError, showSuccess } from '@cvg/react-components'

import { getSelf, updateSelf } from '@services/user'
import { CACHE_KEYS, getOptimisticUIUsers } from '@utils/swr'

import useProfile from '@hooks/useProfile'

const INSTANT_UPDATE_KEYS = ['locale', 'notificationsEnabled']

const useSelfProfileData = () => {
  const { mutate: globalMutate } = useSWRConfig()
  const { getTranslation } = useLanguageContext()

  const { currentUser } = useCustomContext()

  const { isSubmitting, mutate, ...profile } = useProfile(getSelf, {
    cacheKey: CACHE_KEYS.user,
    onChange: (key, value) => {
      if (INSTANT_UPDATE_KEYS.includes(key)) selfUpdate.submit({ [key]: value })
    }
  })

  const selfUpdate = useSubmit(updateSelf, {
    onError: () => {
      profile.onCancel()
      showError(getTranslation('Failed to update user.'), { isPersistent: true })
    },
    onSuccess: newUser => {
      mutate(newUser, { revalidate: false })
      globalMutate(CACHE_KEYS.users, previousUsers => getOptimisticUIUsers(newUser, previousUsers))
      showSuccess(getTranslation('User update successful.'))
    }
  })

  return {
    currentUser,
    isSubmitting: isSubmitting || selfUpdate.isSubmitting,
    showAllOptions: false,
    ...profile
  }
}

export default useSelfProfileData
