import React from 'react'
import { useCustomContext } from '@cvg/react-hooks'

import Header from './Header'
import Statistics from './Statistics'
import Permissions from '@components/UserPermissions'
import Settings from '@components/UserSettings'

import styles from './styles.module.css'

const components = { Statistics, Permissions, Settings }

const View = () => {
  const { view } = useCustomContext()

  const Component = components[view]

  return (
    <section className={styles.view}>
      <Header />
      <Component />
    </section>
  )
}

export default View
