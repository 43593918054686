import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import Metadata from '@components/Metadata'

import Progress from './StepProgress'
import SourceLanguage from './StepSourceLanguage'
import Content from './StepContent'
import Nav from './StepNav'
import Summary from './StepSummary'
import Error from './StepError'

import styles from './styles.module.css'

const Step = ({ children }) => {
  const { currentStep } = useCustomContext()
  const { approved, number } = currentStep

  return (
    <div className={styles.step} data-approved={approved} data-step={number} key={`step-${number}`}>
      {children}
    </div>
  )
}

Step.Metadata = Metadata
Step.Progress = Progress
Step.SourceLanguage = SourceLanguage
Step.Content = Content
Step.Nav = Nav
Step.Summary = Summary
Step.Error = Error

export default Step
