import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import { STEP_ACTIONS } from '@utils/constants'

import Step from '@components/Step'

const Steps = () => {
  const { currentStep, data } = useCustomContext()

  switch (currentStep?.action) {
    case STEP_ACTIONS.review:
      return (
        <Step>
          <Step.Progress />
          <Step.Metadata metadata={data.metadata} />
          <Step.SourceLanguage />
          <Step.Content />
          <Step.Nav />
        </Step>
      )

    case STEP_ACTIONS.submit:
      return (
        <Step>
          <Step.Progress />
          <Step.Summary />
          <Step.Nav />
        </Step>
      )

    default:
      return <Step.Error />
  }
}

export default Steps
