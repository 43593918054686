import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import { useNavigate } from 'react-router-dom'

import auth from '@services/auth'

import { useLanguageContext } from '@cvg/react-hooks'

import AuthenticatedRoutes from './AuthenticatedRoutes'
import AdminRoutes from './AdminRoutes'

import Login from '@pages/Login'
import Logout from '@pages/Logout'
import Home from '@pages/Home'
import Review from '@pages/Review'
import SelfProfile from '@pages/SelfProfile'
import UserAdmin from '@pages/UserAdmin'
import AddUser from '@pages/AddUser'
import UserProfile from '@pages/UserProfile'
import Unsubscribe from '@pages/Unsubscribe'

const AppRoutes = () => {
  useLanguageContext()
  const navigate = useNavigate()

  useEffect(() => {
    auth.setSessionExpirationCallback(() => {
      navigate('/logout')
    })
  }, [])

  return (
    <Routes>
      <Route path='/' element={<AuthenticatedRoutes />}>
        <Route index element={<Home />} />
        <Route path='/review/:id' element={<Review />} />
        <Route path='/users/self' element={<SelfProfile />} />
        <Route path='/users' element={<AdminRoutes />}>
          <Route index element={<UserAdmin />} />
          <Route path='/users/add' element={<AddUser />} />
          <Route path='/users/:userId' element={<UserProfile />} />
        </Route>
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/unsubscribe/:userId' element={<Unsubscribe />} />
    </Routes>
  )
}

export default AppRoutes
