import { useReducer } from 'react'

import { getSortedFlags } from '@utils/flags'

const useReviewReducer = () => {
  const initialState = {
    comment: '',
    currentStepNumber: 1,
    showHelpText: false,
    sourceLanguage: '',
    steps: []
  }

  const reviewReducer = (state, action) => {
    switch (action.type) {
      // Flag individual content items for unacceptable content
      case 'FLAG_CONTENT': {
        let { contentId, flags } = action
        let stepToUpdate = state.steps.find(step => step.number === action.step.number)

        let updatedStep = {
          ...stepToUpdate,
          contentCards: stepToUpdate.contentCards.map(card =>
            card.map(cardContent => ({
              ...cardContent,
              contentItems: cardContent.contentItems.map(item => ({
                ...item,
                ...(item.id === contentId && { approved: false, flags: getSortedFlags(flags) })
              }))
            }))
          )
        }

        return {
          ...state,
          steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
        }
      }

      // Initializes the steps moderator will take to review content
      case 'INITIALIZE_STEPS':
        return {
          ...state,
          sourceLanguage: action.sourceLanguage,
          steps: action.steps
        }

      // Take user to the next step after marking all unflagged content as approved
      case 'NEXT_STEP': {
        let stepToUpdate = state.steps.find(step => step.number === action.step.number)

        let updatedStep = {
          ...stepToUpdate,
          approved: stepToUpdate.contentCards.flat().every(card => card.contentItems.every(item => !item.flags.length)),
          contentCards: stepToUpdate.contentCards.map(card =>
            card.map(cardContent => ({
              ...cardContent,
              contentItems: cardContent.contentItems.map(item => ({ ...item, approved: !item.flags.length }))
            }))
          )
        }

        return {
          ...state,
          currentStepNumber: state.currentStepNumber + 1,
          steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
        }
      }

      // Hide/Show help text related to currently viewed context
      case 'TOGGLE_HELP_TEXT':
        return { ...state, showHelpText: !state.showHelpText }

      // Take user to a previously completed step, and mark every step they backed past as unapproved
      case 'PREVIOUS_STEP':
        return {
          ...state,
          currentStepNumber: action.stepNumber,
          steps: state.steps.map(step => (step.number < action.stepNumber ? step : { ...step, approved: null }))
        }

      // Update moderator comments
      case 'UPDATE_COMMENT': {
        return { ...state, comment: action.event.target.value }
      }

      // Update content items with updates/corrections from moderator
      case 'UPDATE_CONTENT': {
        const { name, value } = action.target

        return {
          ...state,
          steps: state.steps.map(step => ({
            ...step,
            contentCards: step.contentCards?.map(card =>
              card.map(cardContent => ({
                ...cardContent,
                contentItems: cardContent.contentItems.map(contentItem => ({
                  ...contentItem,
                  ...(name.includes(contentItem.id) && { data: value })
                }))
              }))
            )
          }))
        }
      }

      // Correct the source language of all content items
      case 'UPDATE_SOURCE_LANGUAGE':
        return {
          ...state,
          sourceLanguage: action.sourceLanguage,
          steps: state.steps.map(step => ({
            ...step,
            ...(step.action === 'review' && {
              contentCards: step.contentCards.map(cardArray =>
                cardArray.map(card => ({
                  ...card,
                  contentItems: card.contentItems.map(contentItem => ({
                    ...contentItem,
                    ...(contentItem.data.translation && {
                      data: {
                        translation: {
                          ...contentItem.data.translation,
                          source: { ...contentItem.data.translation.source, language: action.sourceLanguage }
                        }
                      }
                    })
                  }))
                }))
              )
            })
          }))
        }

      // Add all failed validations to the errors array
      case 'VALIDATE_CONTENT': {
        const { errors, step } = action
        const updatedStep = { ...step, errors }

        return {
          ...state,
          steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
        }
      }
    }
  }
  const [state, dispatch] = useReducer(reviewReducer, initialState)

  return { state, dispatch }
}

export default useReviewReducer
