import React from 'react'
import { Link } from 'react-router-dom'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import ArrowIcon from '@components/Icons/Arrow'

import styles from '../styles.module.css'

const MyProfile = () => {
  const { getTranslation } = useLanguageContext()
  const { isCurrentUserLoading } = useCustomContext()

  if (isCurrentUserLoading) return

  return (
    <Link to='/users/self' className={styles.link}>
      <div>
        <strong>{getTranslation('My Profile')}</strong>
        <p>{getTranslation('Review progress, update settings, and view permissions.')}</p>
      </div>

      <ArrowIcon />
    </Link>
  )
}

export default MyProfile
