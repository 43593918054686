// This is for an unsubscribe link in moderators' daily email.

import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useLanguageContext, useSubmit } from '@cvg/react-hooks'

import { unsubscribeUser } from '@services/user'

import Page from '@components/Page'

import styles from './styles.module.css'

const Unsubscribe = () => {
  const { getTranslation } = useLanguageContext()
  const { userId } = useParams()

  const { error, isSubmitting, submit } = useSubmit(unsubscribeUser)

  useEffect(() => {
    submit(userId)
  }, [])

  return (
    <Page>
      <Page.Main>
        <Page.AsyncContent isError={!!error} isLoading={isSubmitting}>
          <div className={styles.unsubscribe}>
            <h2>{getTranslation('Successfully Unsubscribed')}</h2>
            <p>{getTranslation('You were unsubscribed from Content Review notifications.')}</p>
            <p>
              {getTranslation('If you would like to receive notifications again, they can be turned on in ')}
              <Link to='/'>{getTranslation('Content Review')}</Link>.
            </p>
          </div>
        </Page.AsyncContent>
      </Page.Main>
    </Page>
  )
}

export default Unsubscribe
