import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFetch, useCustomContext } from '@cvg/react-hooks'

import { CACHE_KEYS } from '@utils/swr'
import { getUsers } from '@services/user'

const useUserAdminData = () => {
  const { isCurrentUserLoading, currentUser, currentUserError } = useCustomContext()
  const navigate = useNavigate()

  if (!currentUser.canManageUsers && !isCurrentUserLoading) navigate('/')

  const [filters, setFilters] = useState({ nameOrEmail: '', role: '' })

  const users = useFetch(getUsers, { cacheKey: CACHE_KEYS.users })

  return {
    error: users.error || currentUserError,
    filters,
    setFilter: updatedFilter => setFilters(prev => ({ ...prev, ...updatedFilter })),
    isLoading: users.isLoading || isCurrentUserLoading,
    users: users.data,
    currentUser
  }
}

export default useUserAdminData
