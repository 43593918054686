import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useCustomContext } from '@cvg/react-hooks'

const AdminRoutes = () => {
  const { currentUser } = useCustomContext()

  return currentUser.canManageUsers ? <Outlet /> : <Navigate to='/' />
}

export default AdminRoutes
