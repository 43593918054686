import dayjs from 'dayjs'
import { del, get, patch, post, put } from '@services/clients/user'

export const deactivateUser = async id => {
  return await del(`/users/${id}`)
}

export const getSelf = async () => {
  return await get('/users/self')
}

export const getUser = async (id, currentUser) => {
  if (id === currentUser.userId) return

  return await get(`/users/${id}`)
}

export const getUsers = async () => {
  return await get('/users')
}

export const getUserStats = async (id, startDate, endDate) => {
  if (!id || !startDate || !endDate) return

  return await get(
    `/users/${id}/stats?startDate=${dayjs(startDate).toISOString()}&endDate=${dayjs(endDate).endOf('day').toISOString()}`
  )
}

export const updateSelf = async payload => {
  return await patch('/users/self', payload)
}

export const upsertUser = async user => {
  const payload = {
    contexts: user.contexts,
    countries: user.countries,
    email: user.email,
    languages: user.languages,
    locale: user.locale,
    name: user.name,
    notificationsEnabled: user.notificationsEnabled,
    role: user.role
  }

  return await post('/users', payload)
}

export const unsubscribeUser = async userId => {
  return await put(`/users/${userId}/unsubscribe`)
}
