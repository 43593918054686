import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const ContentScreening = ({ results }) => {
  const { getTranslation } = useLanguageContext()

  if (!results?.length) return null

  return (
    <div className={styles.screening}>
      <h2>{getTranslation('Risk Assessment')}</h2>
      <ul>
        {results.map(({ level, label, matches }) => (
          <li data-level={level} key={`flag-${label}`}>
            <label>{getTranslation(label)}</label>
            <p>{matches.join(', ')}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ContentScreening
