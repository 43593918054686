import React from 'react'
import { Loader } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const REQUIRED_VALUES = ['role', 'name', 'email', 'contexts', 'countries', 'languages']

const Actions = () => {
  const { getTranslation } = useLanguageContext()
  const { isSubmitting, onCancel, onSubmit, showActions = true, updatedUser } = useCustomContext()

  if (!showActions) return null

  return (
    <div className={styles.actions}>
      <button data-cancel onClick={onCancel}>
        {getTranslation('Cancel')}
      </button>
      <button data-save disabled={isSubmitting || !REQUIRED_VALUES.every(key => updatedUser[key].length)} onClick={onSubmit}>
        {isSubmitting ? <Loader /> : getTranslation('Submit')}
      </button>
    </div>
  )
}

export default Actions
