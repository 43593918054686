import { useNavigate } from 'react-router-dom'
import { useCustomContext } from '@cvg/react-hooks'

import useProfile from '@hooks/useProfile'

const useAddUserData = () => {
  const navigate = useNavigate()
  const { currentUser } = useCustomContext()

  const profile = useProfile(undefined, {
    onCancel: () => navigate('/users'),
    onSuccess: ({ userId }) => navigate(`/users/${userId}`)
  })

  return {
    currentUser,
    ...profile
  }
}

export default useAddUserData
