import React, { useState } from 'react'
import { Loader } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

// TODO move to settings?
const DeactivateUser = () => {
  const { currentUser, isSubmitting, onDeactivate, updatedUser, user } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const [showWarning, setShowWarning] = useState(false)

  if (!user || !currentUser.canDeactivateUser(updatedUser)) return null

  return (
    <div className={styles.deactivate}>
      {!showWarning && (
        <button name='deactivate' onClick={() => setShowWarning(true)}>
          {getTranslation('Deactivate User')}
        </button>
      )}

      {showWarning && (
        <>
          <p>
            <strong>{getTranslation('Warning')}:&nbsp;</strong>
            <span>{getTranslation('___ will no longer be able to log in.', updatedUser.email)}</span>
          </p>
          <div>
            <button name='cancel' onClick={() => setShowWarning(false)}>
              {getTranslation('Cancel')}
            </button>
            <button name='confirm' onClick={onDeactivate} disabled={isSubmitting}>
              {isSubmitting ? <Loader /> : getTranslation('Confirm Deactivation')}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default DeactivateUser
