import React from 'react'

import MenuOption from './MenuOption'

import styles from './styles.module.css'

const Menu = () => {
  return (
    <aside className={styles.menu}>
      <MenuOption option='Statistics' />
      <MenuOption option='Permissions' />
      <MenuOption option='Settings' />
    </aside>
  )
}

export default Menu
