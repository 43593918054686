import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import auth from '@services/auth'
import { resetUser } from '@utils/analytics'

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const logout = async () => {
      await auth.logout()
      resetUser()
      // TODO nuke caches
      navigate('/login')
    }

    logout()
  }, [navigate])

  return null
}

export default Logout
