import React from 'react'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import Statistics from '@components/Icons/Statistics'
import Permissions from '@components/Icons/Permissions'
import Settings from '@components/Icons/Settings'

import styles from './styles.module.css'

const icons = {
  Statistics,
  Permissions,
  Settings
}

const MenuOption = ({ option }) => {
  const { getTranslation } = useLanguageContext()
  const { setView, view } = useCustomContext()

  const Icon = icons[option]

  return (
    <button className={styles.button} data-active={option === view} onClick={() => setView(option)}>
      <Icon />
      <span>{getTranslation(option)}</span>
    </button>
  )
}

export default MenuOption
