import React from 'react'

import BasicInfo from './BasicInfo'
import Permission from './Permission'
import Actions from './Actions'

import styles from './styles.module.css'

const UserPermissions = () => {
  return (
    <div className={styles['user-permissions']}>
      <BasicInfo />

      <Permission type='contexts' />
      <Permission type='countries' />
      <Permission type='languages' />

      <Actions />
    </div>
  )
}

export default UserPermissions
