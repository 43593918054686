import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCustomContext } from '@cvg/react-hooks'

import { getUser } from '@services/user'
import { CACHE_KEYS } from '@utils/swr'

import useProfile from '../../hooks/useProfile'

const INSTANT_UPDATE_KEYS = ['locale', 'notificationsEnabled']

const useUserProfileData = userId => {
  const { currentUser } = useCustomContext()
  const navigate = useNavigate()

  const profile = useProfile(() => getUser(userId, currentUser), {
    cacheKey: `${CACHE_KEYS.users}/${userId}`,
    onChange: (key, value) => {
      if (INSTANT_UPDATE_KEYS.includes(key)) profile.onSubmit({ [key]: value })
    }
  })

  useLayoutEffect(() => {
    if (currentUser.userId === userId) navigate('/users/self')
  }, [currentUser, userId])

  return {
    currentUser,
    showAllOptions: false,
    ...profile
  }
}

export default useUserProfileData
