export const translateText = async ({ source, target }) => {
  if (source.language === target.language)
    return Array.isArray(source.data) ? source.data.map(({ text }) => text) : source.data

  const url = import.meta.env.PUBLIC_GOOGLE_TRANSLATION_BASE_URI

  const params = new URLSearchParams({
    alt: 'json',
    key: import.meta.env.PUBLIC_GOOGLE_TRANSLATION_API_KEY,
    source: source.language,
    format: 'text'
  })

  const body = {
    q: Array.isArray(source.data) ? source.data.map(({ text }) => text) : [source.data],
    target: target.language
  }

  try {
    const response = await fetch(`${url}?${params}`, { method: 'POST', body: JSON.stringify(body) })
    const { data } = await response.json()

    return data.translations.map(({ translatedText }) => translatedText)
  } catch (error) {
    console.error('Translation error:', error)
    return Array.isArray(source.data) ? source.data.map(() => '') : ''
  }
}
