import React from 'react'
import dayjs from 'dayjs'

import { STATS_DATE_MIN } from '@utils/constants'

import Page from '@components/Page'
import DateFilter from '@components/Filters/DateFilter'
import Stat from './Stat'

import useUserStats from '@hooks/useUserStats'

import styles from './styles.module.css'

const Statistics = () => {
  const { error, dates, isLoading, onChange, stats } = useUserStats()

  return (
    <div className={styles.statistics}>
      <DateFilter dates={dates} onChange={onChange} min={STATS_DATE_MIN} max={dayjs().format('YYYY-MM-DD')} />

      <Page.AsyncContent isError={!!error} isLoading={isLoading}>
        {Object.entries(stats).map(([caption, data]) => (
          <Stat caption={caption} data={data} key={caption} />
        ))}
      </Page.AsyncContent>
    </div>
  )
}

export default Statistics
