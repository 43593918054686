import React from 'react'

import PendingRecord from './PendingRecord'

import useContextCard from './useContextCard'
import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const ContextCard = ({ context }) => {
  const { getTranslation } = useLanguageContext()
  const { hasMoreReviews, isLoading, isValidating, observerRef, reviews, rootRef, totalReviews } = useContextCard(context)

  return (
    <div className={styles['context-card']} data-reviews={totalReviews}>
      <h2>
        <strong>{getTranslation(context)}</strong>
        <small>{totalReviews}</small>
      </h2>

      {isLoading && <p>{getTranslation('Loading...')}</p>}
      {!isLoading && !totalReviews && <p>{getTranslation('No pending records')}</p>}

      <ul className={styles.records} ref={rootRef}>
        {reviews.map(record => (
          <PendingRecord context={context} key={record.id} record={record} />
        ))}

        {hasMoreReviews && isValidating && <p>{getTranslation('Loading more records...')}</p>}

        <li ref={observerRef}></li>
      </ul>
    </div>
  )
}

export default ContextCard
