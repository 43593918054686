import { VALIDATIONS } from '@utils/validations'
import { CONTEXT_GROUP_BY, MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { isDifferentLanguage } from '@utils/localization'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required, transcription, translation } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Post',
    captionShort: 'Post',
    contentCardsConfig: [
      [
        {
          label: 'Description',
          contentFilters: { role: 'description' },
          validations: [required, translation],
          validFlagReasons: [
            rejectionReasons.captionDoesNotMatchImage,
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateContent,
            rejectionReasons.intellectualProperty,
            rejectionReasons.lastNames,
            rejectionReasons.linkToExternalWebsite,
            rejectionReasons.needMoreInformation,
            rejectionReasons.personalContactInformation,
            rejectionReasons.politicalMessaging,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.revealsLocation,
            rejectionReasons.sensitiveInformation,
            rejectionReasons.socialMediaInformation,
            rejectionReasons.otherNotSpecified
          ]
        }
      ],
      [
        {
          label: 'Category',
          contentFilters: { role: 'category' },
          validFlagReasons: [rejectionReasons.categoryDoesNotMatchContent]
        }
      ],
      [
        {
          label: 'Impact',
          contentFilters: { role: 'impact' },
          validFlagReasons: [
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.needMoreInformation,
            rejectionReasons.personalContactInformation,
            rejectionReasons.poorDescription,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.socialMediaInformation
          ]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Media',
    captionShort: 'Media',
    optional: true,
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.video },
          validFlagReasons: [
            rejectionReasons.inappropriateClothingForTheCulture,
            rejectionReasons.inappropriateVideo,
            rejectionReasons.intellectualProperty,
            rejectionReasons.lastNames,
            rejectionReasons.linkToExternalWebsite,
            rejectionReasons.needMoreInformation,
            rejectionReasons.personalContactInformation,
            rejectionReasons.politicalMessaging,
            rejectionReasons.poorVideoQuality,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.revealsLocation,
            rejectionReasons.sensitiveInformation,
            rejectionReasons.socialMediaInformation,
            rejectionReasons.otherNotSpecified
          ]
        }
      ],
      [
        {
          label: 'Video Subtitles',
          contentFilters: { mediaType: MEDIA_TYPES.videoTranscription },
          validations: [transcription],
          validFlagReasons: [
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.socialMediaInformation
          ]
        }
      ],
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.image },
          validFlagReasons: [
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateClothingForTheCulture,
            rejectionReasons.inappropriatePhotoContentOrBackground,
            rejectionReasons.intellectualProperty,
            rejectionReasons.lastNames,
            rejectionReasons.linkToExternalWebsite,
            rejectionReasons.needMoreInformation,
            rejectionReasons.personalContactInformation,
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.politicalMessaging,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.revealsLocation,
            rejectionReasons.sensitiveInformation,
            rejectionReasons.socialMediaInformation,
            rejectionReasons.otherNotSpecified
          ]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Video Translation',
    captionShort: 'Translation',
    contentCardsConfig: [
      [
        {
          label: 'Video Translation',
          contentFilters: { mediaType: MEDIA_TYPES.videoTranscription, translation: isDifferentLanguage },
          mediaTypeOverride: MEDIA_TYPES.videoTranslation,
          validations: [translation],
          validFlags: [
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.socialMediaInformation
          ]
        }
      ]
    ],
    optional: true
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps, sortBy: { groupBy: CONTEXT_GROUP_BY.community, sortOrder: 2 } }
