import React, { Fragment } from 'react'
import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const descriptions = {
  moderatedOnly: 'STATS_MODERATED_ONLY',
  edited: 'STATS_EDITED',
  translated: 'STATS_TRANSLATED',
  translatedWithAssistance: 'STATS_TRANSLATED_WITH_ASSISTANCE'
}

// TODO try Items Reviewed cards
const Stat = ({ caption, data }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <fieldset className={styles.stat} key={caption}>
      <legend>{getTranslation(caption)}</legend>
      <dl>
        {Object.entries(data).map(([label, value]) => (
          <Fragment key={label}>
            <dt>
              <strong>{getTranslation(label)}</strong>
              {descriptions[label] && <small>{getTranslation(descriptions[label])}</small>}
            </dt>
            <dd>{value}</dd>
          </Fragment>
        ))}
      </dl>
    </fieldset>
  )
}

export default Stat
