import React from 'react'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Header = () => {
  const { getTranslation } = useLanguageContext()
  const { user } = useCustomContext()

  return (
    <header className={styles.header}>
      <h1>
        <strong>{user.name}</strong>
        <small>({getTranslation(user.role)})</small>
      </h1>
      <small>{user.email}</small>
    </header>
  )
}

export default Header
