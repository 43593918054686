import React from 'react'
import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import DeactivateUser from './DeactivateUser'

const BasicInfo = () => {
  const { currentUser, onChange, updatedUser } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  return (
    <fieldset disabled={!currentUser.canManageUsers}>
      <legend>{getTranslation('Basic Info')}</legend>
      <div data-flex>
        <Input
          label={getTranslation('Role')}
          name='role'
          onChange={e => onChange(e.target.name, e.target.value)}
          type='select'
          value={updatedUser.role}
        >
          {currentUser.permittedRoles.map(role => (
            <option key={role} value={role}>
              {getTranslation(role)}
            </option>
          ))}
        </Input>

        <Input
          label={getTranslation('Name')}
          name='name'
          onChange={e => onChange(e.target.name, e.target.value)}
          type='text'
          value={updatedUser.name}
        />

        <Input
          label={getTranslation('Email')}
          name='email'
          onChange={e => onChange(e.target.name, e.target.value)}
          type='email'
          value={updatedUser.email}
        />

        <DeactivateUser />
      </div>
    </fieldset>
  )
}

export default BasicInfo
