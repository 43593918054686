import React from 'react'
import { useLanguageContext } from '@cvg/react-hooks'

import Page from '@components/Page'
import UserPermissions from '@components/UserPermissions'
import UserSettings from '@components/UserSettings'

import useAddUserData from './useAddUserData'

import styles from './styles.module.css'

const AddUser = () => {
  const { getTranslation } = useLanguageContext()
  const { isLoading, ...values } = useAddUserData()

  return (
    <Page>
      <Page.Header backPath='/users' text='Back to Users' />
      <Page.Main>
        <Page.AsyncContent isLoading={isLoading}>
          <Page.ContextProvider {...values}>
            <section className={styles['add-user']}>
              <h1>{getTranslation('Add User')}</h1>
              {/* TODO make composable so we can do what we want? */}
              <UserPermissions />
              <UserSettings />
            </section>
          </Page.ContextProvider>
        </Page.AsyncContent>
      </Page.Main>
    </Page>
  )
}

export default AddUser
