import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { CustomContextProvider, useFetch } from '@cvg/react-hooks'

import { getUserPermissions } from '@utils/user'
import { CACHE_KEYS } from '@utils/swr'

import auth from '@services/auth'
import { getSelf } from '@services/user'

const AuthenticatedRoutes = () => {
  if (!auth.getUser()) return <Navigate to='/login' />

  const {
    data = { contexts: [], notificationsEnabled: false },
    error,
    isLoading,
    mutate
  } = useFetch(getSelf, { cacheKey: CACHE_KEYS.user })

  return (
    <CustomContextProvider
      context={{
        currentUser: getUserPermissions(data),
        isCurrentUserLoading: isLoading,
        currentUserError: error,
        currentUserMutate: mutate
      }}
    >
      <Outlet />
    </CustomContextProvider>
  )
}

export default AuthenticatedRoutes
