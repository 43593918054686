import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { useVideoTimestamp } from '@hooks/useVideoTimestamp'

import FlagContent from '@components/ContentCard/FlagContent'
import Subtitles from './Subtitles'

import styles from './styles.module.css'

const VideoTranscription = ({ content }) => {
  const { getTranslation } = useLanguageContext()
  const { sourceLanguage } = useCustomContext()

  const [transcription] = content.contentItems
  const video = useVideoTimestamp(document.getElementById('video'))

  return (
    <div className={styles.transcription}>
      <h3>{getTranslation(`${content.label} in ___`, getTranslation(sourceLanguage))}</h3>
      <Subtitles transcription={transcription} video={video} />
      <FlagContent contentItem={transcription} />
    </div>
  )
}

export default VideoTranscription
