import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Progress = () => {
  const { currentStep, steps, goToPreviousStep } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const { number, captionLong } = currentStep

  const handleClick = gotoStepNumber => {
    gotoStepNumber < currentStep.number && goToPreviousStep(gotoStepNumber)
  }

  return (
    <div className={styles['step-progress']}>
      <h1>
        {getTranslation('Step ___:', number)} {getTranslation(captionLong)}
      </h1>
      <div className={styles.steps}>
        {steps.map((step, idx) => (
          <button
            aria-label={`step-${step.number}`}
            className={styles.bullet}
            data-approved={step.approved}
            data-current={step.number === currentStep.number}
            data-label={getTranslation(step.captionShort)}
            disabled={step.number >= currentStep.number}
            key={`bullet-${idx}`}
            onClick={() => handleClick(step.number)}
          />
        ))}
      </div>
    </div>
  )
}

export default Progress
