import React from 'react'

import Language from './Language'
import Notifications from './Notifications'

import styles from './styles.module.css'

const Settings = () => {
  return (
    <div className={styles.settings}>
      <Language />
      <Notifications />
    </div>
  )
}

export default Settings
