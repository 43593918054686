import { useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { useCustomContext, useFetch } from '@cvg/react-hooks'

import { getUserStats } from '@services/user'
import { CACHE_KEYS } from '@utils/swr'

const useUserStats = () => {
  const { userId } = useParams()
  const { currentUser } = useCustomContext()

  const [dates, setDates] = useState({
    From: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
    To: dayjs().format('YYYY-MM-DD')
  })

  const { data: stats = [], ...rest } = useFetch(() => getUserStats(userId || currentUser.userId, dates.From, dates.To), {
    cacheKey: `${CACHE_KEYS.stats}/${userId}/${dates.From}/${dates.To}`
  })

  const onChange = e => setDates(prev => ({ ...prev, [e.target.name]: e.target.value }))

  return {
    dates,
    onChange,
    stats,
    ...rest
  }
}

export default useUserStats
