import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { filterUsers, sortByNameAndEmail } from '@utils/user'

import UserCard from '@components/UserCard'
import Filters from './Filters'

import styles from './styles.module.css'

const Users = () => {
  const navigate = useNavigate()

  const { getTranslation } = useLanguageContext()
  const { filters, users } = useCustomContext()

  return (
    <div className={styles.users}>
      <h1>{getTranslation('User Admin')}</h1>
      <div className={styles.actions}>
        <Filters />
        <button onClick={() => navigate('/users/add')}>{getTranslation('Add User')}</button>
      </div>
      <ul className={styles['user-cards']}>
        {filterUsers(users, filters)
          .toSorted(sortByNameAndEmail)
          .map((user, idx) => (
            <UserCard key={idx} user={user} />
          ))}
      </ul>
    </div>
  )
}

export default Users
