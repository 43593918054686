import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

import styles from './styles.module.css'

// TODO top level filters dir / Filter component?
const Filters = () => {
  const { getTranslation } = useLanguageContext()
  const { currentUser, filters, setFilter } = useCustomContext()

  return (
    <div className={styles.filters}>
      {currentUser.permittedRoles.length > 1 && (
        <Input name='role' onChange={e => setFilter({ role: e.target.value })} type='select' value={filters.role}>
          <option value=''>{getTranslation('All Roles')}</option>
          {Object.entries(currentUser.permittedRoles).map(([key, value]) => (
            <option key={key} value={value}>
              {getTranslation(value)}
            </option>
          ))}
        </Input>
      )}

      <Input
        name='email'
        onChange={nameOrEmail => setFilter({ nameOrEmail })}
        placeholder={getTranslation('Search by name or email')}
        type='search'
        value={filters.nameOrEmail}
      />
    </div>
  )
}

export default Filters
