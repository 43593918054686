import React from 'react'
import { useParams } from 'react-router-dom'

import Page from '@components/Page'
import User from '@components/User'

import useUserProfileData from './useUserProfileData'

const UserProfile = () => {
  const { userId } = useParams()
  const { error, isLoading, ...values } = useUserProfileData(userId)

  return (
    <Page>
      <Page.Header backPath='/users' text='Back to Users' />
      <Page.Main>
        <Page.AsyncContent isError={!!error} isLoading={isLoading}>
          <Page.ContextProvider {...values}>
            <User />
          </Page.ContextProvider>
        </Page.AsyncContent>
      </Page.Main>
    </Page>
  )
}

export default UserProfile
