import React from 'react'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import SelectLanguage from '@components/SelectLanguage'

import styles from '../styles.module.css'

const Language = () => {
  const { getTranslation } = useLanguageContext()
  const { isCurrentUser } = useCustomContext()

  if (!isCurrentUser) return

  return (
    <fieldset className={styles.setting}>
      <legend>{getTranslation('User Preferences')}</legend>
      <SelectLanguage label={getTranslation('Preferred language when using Content Review')} />
    </fieldset>
  )
}

export default Language
