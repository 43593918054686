import React from 'react'
import { Link } from 'react-router-dom'

import UserInfo from './UserInfo'
import UserLocales from './UserLocales'
import UserContexts from './UserContexts'

import styles from './styles.module.css'

const UserCard = ({ user }) => {
  return (
    <li className={styles['user-card']}>
      <Link to={`/users/${user.userId}`}>
        <div className={styles['card-title']}>
          <UserInfo user={user} />
        </div>

        <div className={styles['card-content']}>
          <UserLocales user={user} />
          <UserContexts user={user} />
        </div>
      </Link>
    </li>
  )
}

export default UserCard
