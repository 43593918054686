import React from 'react'

import UnsupportedBrowserFallback from './UnsupportedBrowserFallback'
import FlagContent from '../../FlagContent'

import { useLanguageContext } from '@cvg/react-hooks'
import useHighlights from '@hooks/useHighlights'
import useSelection from '@hooks/useSelection'

import styles from './styles.module.css'

const SelectableText = ({ content }) => {
  // TODO multiple content items?
  const [contentItem] = content.contentItems

  const { getTranslation } = useLanguageContext()

  const { selection } = useSelection(contentItem)
  const { ref } = useHighlights(contentItem)

  return (
    <UnsupportedBrowserFallback>
      <div className={styles['selectable-text']}>
        <h2>{getTranslation(content.label)}</h2>

        {/* TODO figure out why content editable nukes highlights */}
        <div>
          <p data-selectable ref={ref}>
            {contentItem.data}
          </p>
          <FlagContent
            contentItem={contentItem}
            disabled={!selection}
            flaggedContent={selection}
            title={getTranslation('select text to flag')}
          />
        </div>
      </div>
    </UnsupportedBrowserFallback>
  )
}

export default SelectableText
