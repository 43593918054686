import React from 'react'

import Menu from './Menu'
import View from './View'

import styles from './styles.module.css'

const User = () => {
  return (
    <div className={styles.user}>
      <Menu />
      <View />
    </div>
  )
}

export default User
