import { useEffect, useReducer } from 'react'
import { useSWRConfig } from 'swr'
import { useNavigate } from 'react-router-dom'
import { useCustomContext, useFetch, useLanguageContext, useSubmit } from '@cvg/react-hooks'
import { showError, showSuccess } from '@cvg/react-components'

import { deactivateUser, upsertUser } from '@services/user'
import { USER_DEFAULT_VIEW, USER_ROLES } from '@utils/constants'
import { CACHE_KEYS, getOptimisticUIUsers } from '@utils/swr'

const useProfile = (getUser = () => undefined, { cacheKey, onCancel, onChange, onSuccess }) => {
  const { currentUser, currentUserError, isCurrentUserLoading } = useCustomContext()
  const { getTranslation } = useLanguageContext()
  const { mutate: globalMutate } = useSWRConfig()
  const navigate = useNavigate()

  const initialState = {
    updatedUser: {
      name: '',
      email: '',
      role: USER_ROLES.reviewer,
      contexts: [],
      countries: [],
      languages: [],
      notificationsEnabled: false,
      locale: currentUser.locale
    },
    view: USER_DEFAULT_VIEW
  }

  const reducer = (state, action) => {
    const { updates, user, view } = action

    switch (action.type) {
      case 'SET_USER':
        return { ...state, updatedUser: user }
      case 'SET_VIEW':
        return { ...state, view }
      case 'UPDATE_USER':
        return { ...state, updatedUser: { ...state.updatedUser, ...updates } }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { data: user, error, isLoading, mutate } = useFetch(getUser, { cacheKey })

  const userUpsert = useSubmit(upsertUser, {
    onError: () => {
      dispatch({ type: 'SET_USER', user })
      showError(getTranslation('Failed to update user.'), { isPersistent: true })
    },
    onSuccess: newUser => {
      cacheKey && mutate(newUser, { revalidate: false })
      globalMutate(CACHE_KEYS.users, previousUsers => getOptimisticUIUsers(newUser, previousUsers))
      showSuccess(getTranslation('User update successful.'))
      onSuccess && onSuccess(newUser)
    }
  })

  const userDeactivation = useSubmit(deactivateUser, {
    onError: () => showError(getTranslation('User deactivation failed.'), { isPersistent: true }),
    onSuccess: () => {
      globalMutate(CACHE_KEYS.users, previousUsers =>
        previousUsers.filter(previousUser => previousUser.userId !== user.userId)
      )
      showSuccess(getTranslation('User deactivation successful.'))
      navigate('/users')
    }
  })

  useEffect(() => {
    if (user) dispatch({ type: 'SET_USER', user })
  }, [user])

  const callbacks = {
    onCancel: () => {
      onCancel && onCancel()

      dispatch({ type: 'SET_USER', user })
    },
    onChange: (key, value) => {
      onChange && onChange(key, value)

      dispatch({ type: 'UPDATE_USER', updates: { [key]: value } })
    },
    onDeactivate: () => {
      if (user.userId !== currentUser.userId) userDeactivation.submit(user.userId)
    },
    onSubmit: (values = {}) => userUpsert.submit({ ...state.updatedUser, ...values }),
    setView: view => dispatch({ type: 'SET_VIEW', view })
  }

  // TODO verify all of these are needed
  return {
    ...callbacks,
    error: error || currentUserError,
    isCurrentUser: currentUser.userId === user?.userId,
    isLoading: isLoading || isCurrentUserLoading,
    isSubmitting: userUpsert.isSubmitting || userDeactivation.isSubmitting,
    mutate,
    showActions: JSON.stringify(state.updatedUser) !== JSON.stringify(user),
    ...state,
    user
  }
}

export default useProfile
