import { useEffect, useRef, useState } from 'react'

import { getFlaggableContent } from '@utils/flags'

const useFlags = (steps, dispatch) => {
  const [flag, setFlag] = useState()
  const [overlappingFlag, setOverlappingFlag] = useState()

  const firstRunRef = useRef(true)

  // set ai flags
  useEffect(() => {
    // TODO can we do this onLoad or something?
    if (steps.length && firstRunRef.current) {
      getFlaggableContent(steps).forEach(content => {
        content.contentItems.forEach(contentItem => {
          dispatch({
            type: 'FLAG_CONTENT',
            contentId: contentItem.id,
            flags: content.screening.map(item => ({
              details: item.details,
              matches: item.matches,
              reasons: [item.rejectionReason]
            })),
            step: content.step
          })
        })
      })

      firstRunRef.current = false
    }
  }, [steps])

  return { flag, setFlag, overlappingFlag, setOverlappingFlag }
}

export default useFlags
