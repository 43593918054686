import React from 'react'
import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

const permittedKeys = {
  contexts: 'permittedContexts',
  countries: 'permittedCountries',
  languages: 'permittedLanguages'
}

const Permission = ({ type }) => {
  const { currentUser, onChange, showAllOptions = true, updatedUser } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  return (
    <fieldset disabled={!currentUser.canManageUsers}>
      <legend>{getTranslation(type)}</legend>
      <Input
        displayOptions={{
          showAllOptions,
          showUnselectedLabel: getTranslation(`Add ${type}`),
          hideUnselectedLabel: getTranslation(`Hide Unselected ${type}`)
        }}
        name={type}
        options={currentUser[permittedKeys[type]]}
        onSelect={selectedItems => onChange(type, selectedItems.toSorted())}
        selected={updatedUser[type]}
        type='multiselect'
      />
    </fieldset>
  )
}

export default Permission
