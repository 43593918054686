import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

const SelectLanguage = ({ className, ...rest }) => {
  const { getTranslation, languageId, handleLanguageChange, supportedLanguages } = useLanguageContext()

  return (
    <Input
      aria-label={getTranslation('Language')}
      className={className}
      name='language'
      onChange={e => handleLanguageChange(e.target.value)}
      type='select'
      value={languageId}
      {...rest}
    >
      {supportedLanguages.map(language => (
        <option key={language.id} value={language.id}>
          {language.name}
        </option>
      ))}
    </Input>
  )
}

export default SelectLanguage
