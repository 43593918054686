import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

import SubmittedDate from '@components/SubmittedDate'

import styles from './styles.module.css'

const SubHeader = () => {
  const { getTranslation } = useLanguageContext()
  const { context, data, showHelpText, toggleHelpText } = useCustomContext()

  return (
    <div className={styles.subheader}>
      <Input
        label={getTranslation('Show Approval Guidelines')}
        name='help'
        onClick={toggleHelpText}
        type='toggle'
        value={showHelpText}
      />
      <div className={styles.context}>
        <b>{getTranslation(context)}</b>
        <SubmittedDate date={data?.submittedAt} />
      </div>
    </div>
  )
}

export default SubHeader
